import axios from "axios";
import { sendFlash } from "./utilityActions";

export const SET_RECORDS = "UPDATE_RECORDS";
export const SET_RECORD_OPTIONS = "SET_RECORD_OPTIONS";

export const SET_FILTER_OPTIONS_LOADING = "SET_FILTER_OPTIONS_LOADING";
export const SET_RECORDS_LOADING = "SET_RECORDS_LOADING";
export const ADD_UPDATE_RECORD_LOADING = "ADD_UPDATE_RECORD_LOADING";
export const DELETE_RECORD_LOADING = "DELETE_RECORD_LOADING";

export const CLEAN_ADD_RECORD = "CLEAN_ADD_RECORD";

export const ADD_RECORDS_SUCCESS = "ADD_RECORD_SUCCESS";
export const ADD_RECORD_FAILED = "ADD_RECORD_FAILED";

export const UPDATE_RECORD_SUCCESS = "UPDATE_RECORD_SUCCESS";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";

export const setRecordsLoading = () => {
  return {
    type: SET_RECORDS_LOADING,
  };
};

export const setAddUpdateRecordLoading = () => {
  return {
    type: ADD_UPDATE_RECORD_LOADING,
  };
};

export const setDeleteRecordLoading = () => {
  return {
    type: DELETE_RECORD_LOADING,
  };
};

export const setFilterOptionsLoading = () => {
  return {
    type: SET_FILTER_OPTIONS_LOADING,
  };
};

// data via API -> update redux state
export const setRecords = (data) => {
  return {
    type: SET_RECORDS,
    data: data,
  };
};

// data via API -> update redux state
export const setRecordOptions = (data) => {
  return {
    type: SET_RECORD_OPTIONS,
    data: data,
  };
};

// data via API -> update redux state
export const addRecordSuccess = (data) => {
  return {
    type: ADD_RECORDS_SUCCESS,
    data: data,
  };
};

// data via API -> update redux state
export const addRecordFailed = (data) => {
  return {
    type: ADD_RECORD_FAILED,
    data: data,
  };
};

// data via API -> update redux state
export const updateRecordSuccess = (data) => {
  return {
    type: UPDATE_RECORD_SUCCESS,
    data: data,
  };
};

// Action to remove the deleted record from the Redux state
export const deleteRecordSuccess = (recordId) => {
  return {
    type: DELETE_RECORD_SUCCESS,
    data: recordId,
  };
};

// data via API -> update redux state
export const cleanAddRecordRedux = (data) => {
  return {
    type: CLEAN_ADD_RECORD,
  };
};

// Add record to database
export const addRecord = (record) => (dispatch) => {
  dispatch(setAddUpdateRecordLoading());
  axios.post("/api/records/add-single-record", record).then(
    (response) => {
      dispatch(addRecordSuccess(response.data));
    },
    (error) => {
      console.log(error);
      dispatch(addRecordFailed(error));
    }
  );
};

// update record
export const updateRecord = (record) => (dispatch) => {
  dispatch(setAddUpdateRecordLoading());
  axios.put("/api/records/update-record", record).then(
    (response) => {
      dispatch(updateRecordSuccess(response.data));
    },
    (error) => {
      console.log(error);
      dispatch(addRecordFailed(error));
    }
  );
};

// Add record to database
export const cleanAddRecord = () => (dispatch) => {
  dispatch(cleanAddRecordRedux());
};

// Fetch records from backend
export const getRecords = () => (dispatch) => {
  dispatch(setRecordsLoading());
  axios.get("/api/records/get-records").then(
    (response) => {
      dispatch(setRecords(response.data));
    },
    (error) => {
      console.log(error);
    }
  );
};

// Fetch searched records by job title from backend
export const getSearchRecords = (searchText, cancelToken) => (dispatch) => {
  dispatch(setRecordsLoading());
  axios
    .get(
      `/api/records/get-search-records?jobTitle=${encodeURIComponent(
        searchText
      )}`,
      {
        cancelToken: cancelToken, // Attach the cancel token here
      }
    )
    .then(
      (response) => {
        dispatch(setRecords(response.data));
      },
      (error) => {
        console.log(error);
      }
    );
};

// Fetch filterd records from backend
export const getFilteredRecords = (filters) => (dispatch) => {
  dispatch(setRecordsLoading());
  const {
    MBTI_1,
    MBTI_2,
    MBTI_3,
    SII_1,
    SII_2,
    SII_3,
    salary,
    min_edu,
    stress_level,
    familyType,
  } = filters;
  axios
    .get(
      `/api/records/get-records?MBTI_1=${encodeURIComponent(
        MBTI_1
      )}&MBTI_2=${encodeURIComponent(MBTI_2)}&MBTI_3=${encodeURIComponent(
        MBTI_3
      )}&SII_1=${encodeURIComponent(SII_1)}&SII_2=${encodeURIComponent(
        SII_2
      )}&SII_3=${encodeURIComponent(SII_3)}&salary=${encodeURIComponent(
        salary
      )}&min_edu=${encodeURIComponent(
        min_edu
      )}&stress_level=${encodeURIComponent(
        stress_level
      )}&familyType=${encodeURIComponent(familyType)}`
    )
    .then(
      (response) => {
        dispatch(setRecords(response.data));
      },
      (error) => {
        console.log(error);
      }
    );
};

// Fetch records options for dropdowns
export const getRecordOptions = (filters) => (dispatch) => {
  dispatch(setFilterOptionsLoading());
  axios.get(`/api/records/get-filter-options`).then(
    (response) => {
      dispatch(setRecordOptions(response.data));
    },
    (error) => {
      console.log(error);
    }
  );
};

// Delete single record

export const deleteRecord = (recordId, onClose) => (dispatch) => {
  dispatch(setDeleteRecordLoading());
  axios.delete(`/api/records/delete-record/${recordId}`).then(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        // delete that one record from records
        dispatch(
          deleteRecordSuccess({ id: recordId, response: response.data })
        );
        // setTimeout(() => {
        onClose(false);
        // dispatch(cleanAddRecordRedux());
        dispatch(sendFlash("Record deleted!", "success"));

        // }, 3000);
      }
    },
    (error) => {
      console.log(error);
    }
  );
};
