import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import { getRecordOptions } from "../../../actions";

// MUI Imports
import WestIcon from "@mui/icons-material/West";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { Box } from "@mui/material";
import SingleSelect from "./SingleSelect";

import BackgroundImage from "../../../images/graphics/admin-record-bg.png";
import Logo from "../../../images/logo_black.svg";
import "./styles.css";

// Styles
const useStyles = makeStyles((theme) => ({
  headerContainer: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // width: "100%",
    display: "grid",
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    width: "100%",
  },
  typeContainer: {
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    display: "grid",
    gap: "17px",
    [theme.breakpoints.down("lg")]: {
      gap: "10px",
    },
  },

  heading: {
    flex: "1 1 0%",
    fontSize: "46px",
    fontWeight: "500",
    color: "#7D80C6",
    textAlign: "center",
  },

  typeHeading: {
    fontSize: "36px",
    fontWeight: "400",
    color: "#383050",
    textAlign: "center",
    textTransform: "uppercase",
  },

  upperContainer: {
    display: "flex",
    gap: "27px",
    [theme.breakpoints.down("lg")]: {
      gap: "16px",
    },
  },

  lowerContainer: {
    display: "grid",
    gridTemplateColumns: " repeat(3, minmax(0, 1fr))",
    fontSize: "0.875rem",
    padding: "230px 117px 0 117px",
    gap: "30px",
    [theme.breakpoints.down("lg")]: {
      padding: "190px 90px 0 90px",
      gap: "20px",
    },
  },

  logo: {
    width: "112px",
    marginLeft: "6px",
  },
  backIcon: {
    fontSize: "2.3rem !important",
    color: "#7D80C6 !important",
  },

  addBtnBox: {
    flex: "1 1 0%",
    display: "flex",
    justifyContent: "flex-end",
  },
  searchBtn: {
    padding: "10px 40px",
    fontWeight: " 500",
    fontSize: "14px",
    fontFamily: "Roboto",
    backgroundColor: "#7D80C6",
    color: "white",
    borderRadius: "4px",
    border: "none",
    marginTop: "25px",
    marginBottom: "30px",
    cursor: "pointer",
  },
}));
function SearchRecord(props) {
  const classes = useStyles();
  const history = useHistory();

  const { optionsLoading, filters } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOptions, setSelectedOptions] = useState({
    MBTI_1: "",
    MBTI_2: "",
    MBTI_3: "",
    SII_1: "",
    SII_2: "",
    SII_3: "",
    salary: "",
    min_edu: "",
    familyType: "",
    stress_level: "",
  });

  const selectOnChangeHandler = (name, value) => {
    setErrorMessage("");
    setSelectedOptions({
      ...selectedOptions,
      [name]: value,
    });
    // console.log(selectedOptions);
  };

  // atleast one filter should be selected
  const checkSelectedOptions = () => {
    const filterChecked = Object.keys(selectedOptions).some(
      (key) => selectedOptions[key] !== ""
    );
    return filterChecked;
  };

  const redirectToResults = () => {
    if (!checkSelectedOptions()) {
      setErrorMessage("Atleast one filter is required");
      return;
    }
    const {
      MBTI_1,
      MBTI_2,
      MBTI_3,
      SII_1,
      SII_2,
      SII_3,
      salary,
      min_edu,
      stress_level,
      familyType,
    } = selectedOptions;
    const searchData = `MBTI_1=${encodeURIComponent(
      MBTI_1
    )}&MBTI_2=${encodeURIComponent(MBTI_2)}&MBTI_3=${encodeURIComponent(
      MBTI_3
    )}&SII_1=${encodeURIComponent(SII_1)}&SII_2=${encodeURIComponent(
      SII_2
    )}&SII_3=${encodeURIComponent(SII_3)}&salary=${encodeURIComponent(
      salary
    )}&min_edu=${encodeURIComponent(min_edu)}&stress_level=${encodeURIComponent(
      stress_level
    )}&familyType=${encodeURIComponent(familyType)}`;

    history.push(`/admin/search-record/result?${searchData}`);
  };

  useEffect(() => {
    props.getRecordOptions();
  }, []);

  return (
    <Box
      sx={{
        paddingY: "30px",
        paddingX: "24px",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: "white",
        backgroundSize: "cover",
        minHeight: "100%",
        fontFamily: "Bebas Neue",
      }}
    >
      <div>
        <div className={classes.headerContainer}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/admin">
              <WestIcon fontSize="inherit" className={classes.backIcon} />
            </Link>
          </div>
          <div className={classes.heading}>Career Planning Tool</div>
          <div className={classes.addBtnBox}>
            <Link to="/admin/database">
              <button
                className={classes.searchBtn}
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                Manage Database
              </button>
            </Link>
          </div>
        </div>

        <div className={classes.upperContainer}>
          {/* SII code fields */}

          <div style={{ width: "100%" }}>
            <h2 className={classes.typeHeading}>SII CODE</h2>
            <div className={classes.typeContainer}>
              <SingleSelect
                label="Interest 1"
                name="SII_1"
                selectOnChangeHandler={selectOnChangeHandler}
                options={filters?.SII_1}
              />
              <SingleSelect
                label="Interest 2"
                name="SII_2"
                selectOnChangeHandler={selectOnChangeHandler}
                options={filters?.SII_2}
              />
              <SingleSelect
                label="Interest 3"
                name="SII_3"
                selectOnChangeHandler={selectOnChangeHandler}
                options={filters?.SII_3}
              />
            </div>
          </div>

          {/* MBTI code fields */}

          <div style={{ width: "100%" }}>
            <h2 className={classes.typeHeading}>MBTI CODE</h2>
            <div className={classes.typeContainer}>
              <SingleSelect
                label="Introvert/Extrovert"
                name="MBTI_1"
                selectOnChangeHandler={selectOnChangeHandler}
                options={filters?.MBTI_1}
              />

              <SingleSelect
                label="Motivation"
                name="MBTI_2"
                selectOnChangeHandler={selectOnChangeHandler}
                options={filters?.MBTI_2}
              />

              <SingleSelect
                label="Judging/Perceiving"
                name="MBTI_3"
                selectOnChangeHandler={selectOnChangeHandler}
                options={filters?.MBTI_3}
              />
            </div>
          </div>
        </div>

        <div className={classes.lowerContainer}>
          <div>
            <h3
              className={classes.typeHeading}
              style={{ marginBottom: "20px" }}
            >
              Salary Level
            </h3>

            <SingleSelect
              name="salary"
              selectOnChangeHandler={selectOnChangeHandler}
              options={filters?.salary}
            />
          </div>

          <div>
            <h3
              className={classes.typeHeading}
              style={{ marginBottom: "20px" }}
            >
              Education
            </h3>
            <SingleSelect
              name="min_edu"
              selectOnChangeHandler={selectOnChangeHandler}
              options={filters?.min_edu}
            />
          </div>

          <div>
            <h3
              className={classes.typeHeading}
              style={{ marginBottom: "20px" }}
            >
              Stress Level
            </h3>

            <SingleSelect
              name="stress_level"
              selectOnChangeHandler={selectOnChangeHandler}
              options={filters?.stress_level}
            />
          </div>
          <div />
          <div>
            <h3
              className={classes.typeHeading}
              style={{ marginBottom: "20px" }}
            >
              Job Family
            </h3>
            <SingleSelect
              name="familyType"
              selectOnChangeHandler={selectOnChangeHandler}
              options={filters?.familyType}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          {errorMessage && (
            <div
              style={{
                fontFamily: "Roboto",
                color: "red",
                fontSize: "14px",
                paddingTop: "8px",
              }}
            >
              {errorMessage}
            </div>
          )}
          <button className={classes.searchBtn} onClick={redirectToResults}>
            Search
          </button>
        </div>
      </div>
    </Box>
  );
}

const mapDispatchToProps = {
  getRecordOptions,
  // getFilteredRecords,
};

const mapStateToProps = (state) => {
  return {
    // records: state.records.records,
    filters: state.records.filters,
    optionsLoading: state.records.optionsLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchRecord);
