/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import OutsideClickHandler from "react-outside-click-handler";
import { getRecordOptions } from "../../../actions";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Lato",
    position: "relative",
    width: "100%",
    minWidth: "145px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#383050",
    marginBottom: "6px",
    textTransform: "uppercase",
  },
  dropdown: {
    position: "absolute",
    right: "0px",
    top: "0px",
    zIndex: 1000,
    fontSize: "12px",
    maxHeight: "20vh",
    minHeight: "8vh",
    width: "100%",
    overflowY: "auto",
    backgroundColor: "white",
    padding: "10px 14px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    border: "1px solid #c7c8cb",
    borderRadius: "4px",
  },
  inputfield: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    outline: "none",
    width: "100%",
    // borderRadius: " 4px",
    backgroundColor: " white",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "12px",
    paddingBottom: "12px",
    // border: "1px solid #383050",
    borderTop: "1px",
    borderRight: "1px",
    borderBottom: 0,
    borderLeft: 0,

    borderColor: "#383050",
    borderStyle: "solid",
    cursor: "pointer",
  },
  selectedValue: {
    fontFamily: "Lato",
    fontSize: " 14px",
    fontWeight: "400",
    margin: "0px",
    color: "#0000004D",
  },

  lineClamp1: {
    // to handle overflow
    display: "-webkit-box",
    minHeight: "19px",
    WebkitBoxOrient: " vertical",
    WebkitLineClamp: 1,
    LineClamp: 1,
    overflow: "hidden",
    wordBreak: " break-all",
  },
}));

const SelectedNormalizeValue = (name, value) => {
  if (name === "salary" && value == "1") {
    return "<$100k";
  } else if (name === "salary" && value == "2") {
    return "$100k+";
  } else if (name === "salary" && value != "1" && value != "2") {
    return null;
  } else if (name === "stress_level" && value == "L") {
    return "Low";
  } else if (name === "stress_level" && value == "H") {
    return "High";
  } else if (name === "stress_level" && value != "H" && value != "L") {
    return null;
  } else {
    return value;
  }
};

const AddRecordSingleSelect = ({
  // label,
  name,
  selectOnChangeHandler,
  options,
  selectValue = "",
  ...props
}) => {
  const classes = useStyles();
  const { optionsLoading, filters } = props;

  const [dropdown, setDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selectValue);

  const changeHandler = (name, value) => {
    selectOnChangeHandler(name, value);
    setSelectedValue(value);
    setDropdown(false);
  };

  // when reset button was clicked reset it also
  useEffect(() => {
    if (selectValue !== "") {
      setSelectedValue(selectValue);
    }
  }, [selectValue]);

  return (
    <div>
      {/* {label && <h3 className={classes.label}>{label}</h3>} */}
      <div className={classes.container}>
        <div
          onClick={() => setDropdown(true)}
          className={classes.inputfield}
          style={{ borderRight: name === "min_edu" && 0 }}
        >
          {selectedValue ? (
            <p
              className={`${classes.selectedValue} ${classes.lineClamp1}`}
              style={{ color: "black" }}
            >
              {SelectedNormalizeValue(name, selectedValue)}
            </p>
          ) : (
            <p className={classes.selectedValue}>Select</p>
          )}

          <ArrowForwardIosRoundedIcon
            fontSize="inherit"
            htmlColor="#0000004D"
            style={{ rotate: "90deg", fontSize: "16px" }}
          />
        </div>
        {dropdown && (
          <OutsideClickHandler onOutsideClick={() => setDropdown(false)}>
            <div className={`${classes.dropdown} minimal-y-scrollbar`}>
              {optionsLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress style={{ color: "#7D80C6" }} size={20} />
                </div>
              )}
              {!optionsLoading &&
                options &&
                options.map((value, index) => {
                  if (value === null || value === "") {
                    return null;
                  } else {
                    return showingOptionValue(
                      index,
                      name,
                      value,
                      changeHandler
                    );
                  }
                })}
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </div>
  );
};

// this function changes text to show of values
const showingOptionValue = (index, name, value, changeHandler) => {
  if (name === "salary" && value == "1") {
    return (
      <SingleOption
        index={index}
        name={name}
        value={value}
        changeHandler={changeHandler}
      >
        {"<$100k"}
      </SingleOption>
    );
  } else if (name === "salary" && value == "2") {
    return (
      <SingleOption
        index={index}
        name={name}
        value={value}
        changeHandler={changeHandler}
      >
        $100k+
      </SingleOption>
    );
  } else if (name === "salary" && value != "1" && value != "2") {
    return null;
  } else if (name === "stress_level" && value == "L") {
    return (
      <SingleOption
        index={index}
        name={name}
        value={value}
        changeHandler={changeHandler}
      >
        Low
      </SingleOption>
    );
  } else if (name === "stress_level" && value == "H") {
    return (
      <SingleOption
        index={index}
        name={name}
        value={value}
        changeHandler={changeHandler}
      >
        High
      </SingleOption>
    );
  } else if (name === "stress_level" && value != "H" && value != "L") {
    return null;
  } else {
    return (
      <SingleOption
        index={index}
        name={name}
        value={value}
        changeHandler={changeHandler}
      >
        {value}
      </SingleOption>
    );
  }
};

const SingleOption = ({ index, name, value, changeHandler, children }) => {
  const classes = useStyles();

  return (
    <div
      key={index}
      onClick={() => changeHandler(name, value)}
      className={classes.lineClamp1}
      style={{ cursor: "pointer" }}
    >
      {children}
    </div>
  );
};

const mapDispatchToProps = {
  getRecordOptions,
};

const mapStateToProps = (state) => {
  return {
    filters: state.records.filters,
    optionsLoading: state.records.optionsLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRecordSingleSelect);
